export class PublicationData {
  public id:number;
  public name:string;
  public link:string;
  constructor(json:any){
    this.id=json.id;
    this.link=json.link;
    this.name=json.name;
  }
}
