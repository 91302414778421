import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router} from "@angular/router";
import {PublicationData} from "../models/publication-data";
import {DownloaderServiceService} from "./downloader-service.service";
import {Observable} from "rxjs/index";
import {of} from 'rxjs';
import {catchError} from "rxjs/internal/operators";

@Injectable({
  providedIn: 'root'
})
export class DownloaderResolveService implements Resolve<PublicationData> {

  constructor(private _downloaderService: DownloaderServiceService, private _router: Router) {
  }

  resolve(route: ActivatedRouteSnapshot) {

    return this._downloaderService.getPublicationWithoutLink(route.paramMap.get('id')).pipe(catchError(error => {

      this._router.navigate(['/error']);
      return of(null);
    }));

  }
}
