import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http';

import {Observable} from "rxjs/index";



@Injectable()
export class RequestService {


  constructor(private http: HttpClient) {
  }

  get<T>(url: string, headers?: HttpHeaders | null): Observable<T> {
    const expandedHeaders = this.prepareHeader(headers);
    return this.http.get<T>(url, expandedHeaders);

  }

  post<T>(url: string, body: string, headers?: HttpHeaders | null): Observable<T> {
    const expandedHeaders = this.prepareHeader(headers);
    return this.http.post<T>(url, body, expandedHeaders);

  }

  put<T>(url: string, body: any, headers?: HttpHeaders | null): Observable<T> {
    const expandedHeaders = this.prepareHeader(headers);
    return this.http.put<T>(url, body, expandedHeaders);

  }

  patch<T>(url: string, body: string, headers?: HttpHeaders | null): Observable<T> {
    const expandedHeaders = this.prepareHeader(headers);
    return this.http.patch<T>(url, body, expandedHeaders);

  }

  delete<T>(url: string, headers?: HttpHeaders | null): Observable<T> {
    const expandedHeaders = this.prepareHeader(headers);
    return this.http.delete<T>(url, expandedHeaders);

  }

  private prepareHeader(headers: HttpHeaders | null): object {
    headers = headers || new HttpHeaders();

    return {
      headers: headers,
      observe: 'body'
    };
  }
}
