import { Injectable } from '@angular/core';
import {RequestService} from "./request.service";
import {PublicationData} from "../models/publication-data";

import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DownloaderServiceService {
  PUBLICATION_API = "/api/publications";
  constructor(private _requestService: RequestService) {
  }
  getPublicationWithoutLink(id) {
    return this._requestService.get<any>(this.PUBLICATION_API + "/withoutLink/" + id).pipe(map(
      (response) => {

        return new PublicationData(response["publication"]);

      }
    ));
  }
  postPublicationLink(id,body) {
    return this._requestService.post<any>(this.PUBLICATION_API + "/withLink/" + id,body).pipe(map(
      (response) => {

        return new PublicationData(response["publication"]);

      }
    ));
  }

}
