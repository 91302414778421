import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {DownloaderComponent} from "./downloader/downloader.component";
import {ErrorComponent} from "./error/error.component";
import {DownloaderResolveService} from "./services/downloader-resolve.service";

const routes: Routes = [
  {path: '', redirectTo: 'error', pathMatch: 'full'},

  {
    path: 'downloader/:id', component: DownloaderComponent, resolve: {
      publication: DownloaderResolveService
    }
  },
  {path: 'error', component: ErrorComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
