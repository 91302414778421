import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {DownloaderServiceService} from "../services/downloader-service.service";
import {PublicationData} from "../models/publication-data";
import {ActivatedRoute, Router} from "@angular/router";
declare var jQuery:any;

declare var grecaptcha: any;

@Component({
  selector: 'app-downloader',
  templateUrl: './downloader.component.html',
  styleUrls: ['./downloader.component.css']

})
export class DownloaderComponent implements OnInit {

  publication: PublicationData;
  id: any;
  el: ElementRef;
  buttonDisabled: boolean = true;

  constructor(private _el: ElementRef, private _downloadService: DownloaderServiceService, private _route: ActivatedRoute, private _router: Router) {
    this.el = _el.nativeElement;
  }

  ngOnInit() {
    this.publication = this._route.snapshot.data['publication'];
    this.id = this._route.snapshot.params['id'];


    setTimeout(() => {
     // console.log(document.getElementsByClassName("g-recaptcha"));
      grecaptcha.render(document.getElementsByClassName("g-recaptcha")[0], {
        'sitekey': '6LdZwV0UAAAAAGiAWK8iXTSfVV1RN9REUGzWQuYo',
        'callback': (data) => {
          if (data) {
            this.buttonDisabled = false;
          }
        }
      });
    }, 500)
  }

  submit() {

    let body={
      "g-recaptcha-response":jQuery("#g-recaptcha-response").val(),
      "id":this.id
    };


    const sub = this._downloadService.postPublicationLink(this.id,body).subscribe((response) => {

      sub.unsubscribe();
      var filePath = response.link;
      var link = document.createElement('a');
      link.href = filePath;
      link.download = filePath.substr(filePath.lastIndexOf('/') + 1);
      link.click();

    }, (error) => {

      sub.unsubscribe();
      this._router.navigate(['/error']);
    });
  }

}
